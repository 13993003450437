import "./style.css"



const Header = () => {

    return(
<header>
    <h1>To Do</h1>
</header>
    )
}


export default Header;